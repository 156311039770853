.app {
  width: 100vw;
  height: 100vh;
}

.appMain {
  padding-top: 20px;
}

.display {
  padding: 5px;
  background-color: #FEF7FF;
  border: ridge;
}

.display div {
  text-align: right;
  font-family: inherit !important;
}

.display div div {
  min-height: 1.6rem;
}

.display div :nth-child(2) {
  font-size: larger;
}

.warningBox {
  padding-top: 10px;
}

